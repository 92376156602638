import * as React from 'react'
import PropTypes from 'prop-types'

const ReviewRoll = ({ reviewItems }) => {
  return (
    <div className="columns is-multiline review-shadow">
      {reviewItems.map(item => (
        <div key={item.name} className="column is-one-third">
          <section className="has-text-centered has-text-white review-gradient p-5">
            <h4 className="is-uppercase has-text-white">{item.title}</h4>
            <p>{item.text}</p>
            <label className="has-text-weight-bold">{item.name}</label>
          </section>
        </div>
      ))}
    </div>
  )
}

ReviewRoll.propTypes = {
  reviewItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      name: PropTypes.string,
    })
  ),
}

export default ReviewRoll
