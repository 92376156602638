import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

const RolloverImage = ({
  src,
  hoverSrc,
  style,
  disabled,
  onClick,
  className,
}) => {
  const [imageSrc, setImageSrc] = useState(src)

  const mouseOver = useCallback(() => {
    setImageSrc(hoverSrc)
  }, [hoverSrc])

  const mouseOut = useCallback(() => {
    setImageSrc(src)
  }, [src])

  const handleClick = event => {
    if (!onClick) return
    if (!disabled) {
      onClick(event)
    }
  }

  return (
    // eslint-disable-next-line
    <img
      src={imageSrc}
      style={style}
      onMouseOver={mouseOver}
      onMouseOut={mouseOut}
      onClick={handleClick}
      className={className}
    />
  )
}

RolloverImage.propTypes = {
  src: PropTypes.string.isRequired,
  hoverSrc: PropTypes.string.isRequired,
  style: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default RolloverImage
