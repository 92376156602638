import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export function PageHero() {
  return (
    <div style={{ display: 'grid' }}>
      <StaticImage
        style={{
          gridArea: '1/1',
          maxHeight: 600,
        }}
        layout="fullWidth"
        aspectRatio={3 / 1}
        alt=""
        src={'../../../static/img/hero.jpg'}
        formats={['auto', 'webp', 'avif']}
      />
      <div
        style={{
          gridArea: '1/1',
          position: 'relative',
          placeItems: 'center',
          display: 'grid',
        }}
      ></div>
    </div>
  )
}
