import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Link, graphql } from 'gatsby'

import { PageHero } from '../components/PageHero'
import Layout from '../components/Layout'
import Features from '../components/Features'
import { ComingSoon } from '../components/ComingSoon'
import Reviews from '../components/Reviews'
import * as ROUTES from '../constants/routes'

// eslint-disable-next-line
export const IndexPageTemplate = ({ intro, main }) => {
  return (
    <Fragment>
      <PageHero />
      <section className="section home-jumbotron">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content has-text-white has-text-centered">
                  <h1 className="is-uppercase has-text-white">
                    Connecting You
                  </h1>
                  <p>
                    99 Sense creates, encourages and maintains an economic
                    ecosystem in different regions - suburbs, provinces, and
                    countries.
                  </p>
                  <p>It starts right here… at home!</p>
                  <p>
                    Sign up in order to enjoy the benefits of one or more of the
                    following profiles from the property industry:
                  </p>
                  <p>
                    <Link
                      className="button is-info is-rounded"
                      to={ROUTES.SIGN_UP}
                    >
                      Sign Up
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <Features features={intro.blurbs} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ComingSoon />
      <section className="section section-gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content has-text-white is-spaced p-5">
                <h1 className="is-uppercase has-text-white">
                  Sign Up Today To Enjoy All
                  <br />
                  The Benefits Of The 99Sense Platform
                </h1>
                <p>
                  <Link
                    className="button is-info is-rounded"
                    to={ROUTES.SIGN_UP}
                  >
                    Sign Up
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section review-jumbotron">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content is-spaced py-5">
                <h1 className="is-uppercase has-text-link-dark mb-5">
                  What Our
                  <br />
                  Users Are Saying
                </h1>
                <Reviews reviewItems={main.reviews} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

IndexPageTemplate.propTypes = {
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    reviews: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate intro={frontmatter.intro} main={frontmatter.main} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            imagemain {
              extension
              publicURL
            }
            imagehover {
              extension
              publicURL
            }
            title
            text
          }
          heading
          description
        }
        main {
          reviews {
            title
            text
            name
          }
        }
      }
    }
  }
`
