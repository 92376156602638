import * as React from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'

import RolloverImage from './RolloverImage'

const Features = ({ features }) => {
  return (
    <div className="columns is-multiline">
      {features.map(feature => (
        <div key={v4()} className="column is-one-third">
          <section className="section feature-top">
            <RolloverImage
              src={feature.imagemain.publicURL}
              hoverSrc={feature.imagehover.publicURL}
            />
          </section>
          <section className="has-text-centered has-text-white feature-bottom p-5">
            <h4 className="is-uppercase has-text-white">{feature.title}</h4>
            <p>{feature.text}</p>
          </section>
        </div>
      ))}
    </div>
  )
}

Features.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      title: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

export default Features
