import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export function ComingSoon() {
  return (
    <div style={{ display: 'grid' }}>
      <StaticImage
        style={{
          gridArea: '1/1',
          maxHeight: 600,
        }}
        layout="fullWidth"
        aspectRatio={3 / 1}
        alt=""
        src={'../../../static/img/coming-soon.jpg'}
        formats={['auto', 'webp', 'avif']}
      />
      <div
        style={{
          gridArea: '1/1',
          position: 'relative',
          placeItems: 'center',
          display: 'grid',
        }}
      >
        <div className="container">
          <div className="content">
            <h1 className="is-uppercase has-text-white">
              Our Statistics
              <br />
              Total Value of Transactions Through 99Sense
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}
